import React from "react";
import { LazyLoadImages } from "@quintype/components";
import { HomePagePreview as HomePagePreviewBase } from "../components/pages/home-preview";
import { NotFoundPage as NotFoundPageBase } from "../components/pages/not-found";
import { TaboolaAd } from "../components/atoms/taboola-ad";
import { VikatanSectionHeader } from "../components/rows/ad-free";

function wrapLazyLoadImages(Template) {
  const ImageLazyLoadedComponent = props => {
    return (
      <React.Fragment>
        <VikatanSectionHeader />
        <LazyLoadImages margin={"150px"}>
          <Template {...props} />
        </LazyLoadImages>
        <div className="taboola-outer container">
          <TaboolaAd
            config={{
              mode: "thumbnails-a",
              id: "taboola-below-category-thumbnails",
              placement: "Below Category Thumbnails"
            }}
            url={global && global.location && global.location.href}
            className={"taboola-article"}
            directory={"category"}
            flush={true}
          />
        </div>
      </React.Fragment>
    );
  };
  ImageLazyLoadedComponent.displayName = `WrapLazyLoadImages(${Template.name})`;
  return ImageLazyLoadedComponent;
}

export const HomePagePreview = wrapLazyLoadImages(HomePagePreviewBase);
export const NotFoundPage = wrapLazyLoadImages(NotFoundPageBase);
